import {createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import reducer from './reducers/index'
import thunk from 'redux-thunk'

// const initialState = {}

// const reducer = {}
const middleware = [thunk]

const store = createStore(
    reducer,
    // initialState,
    // window.__REDUX_DEVTOOLS_EXTENSION__ &&
    // window.__REDUX_DEVTOOLS_EXTENSION__()
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store
