import React, { useState } from "react";
import {useDispatch} from 'react-redux'
import Axios from 'axios'
import {cargarNotaria} from '../actions/notariaAction'


// reactstrap components

// core components
import PresentationHeader from "components/Headers/PresentationHeader.js";
// sections for this page
import SectionSummary from "./presentation-sections/SectionSummary.js";
import SectionComponents from "./presentation-sections/SectionComponents.js";
// import SectionCards from "./presentation-sections/SectionCards.js";
import SectionContent from "./presentation-sections/SectionContent.js";

// import SectionSharing from "./presentation-sections/SectionSharing.js";
import SectionContactUs from "./sections-sections/SectionContactUs.js";
import Logos from "./sections-sections/Logos.js";
import {  Modal } from "reactstrap";
function Presentation() {

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
 
  React.useEffect(() => {
    
    const consultar = async () =>{
      let config = {
        method: 'GET',
        mode: 'cors',
        headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
        
      }

      const resultado = await Axios.get(process.env.REACT_APP_UrlApi+'notaria',config)

      if(resultado.status === 200){
        let dataTemp ={}        
        resultado.data.forEach((element)=>{
          dataTemp[element.variable]=element.value
        })
        dispatch(cargarNotaria(dataTemp))
      }
   }
   consultar()
  },[]);
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("section-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    // this function is used to make the right bullets list
    // (the bellow <nav id="cd-vertical-nav">)
    // active when changeing the section on scroll
    const updateView = () => {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 6) - 1;
        if (
          contentSections[i].offsetTop - window.innerHeight / 2 <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", updateView);
    };
  });
  React.useEffect(() => {
    setOpen(true);
  }, []);
  return (
    <>
      <div className="section-space" />
      <PresentationHeader />
      <Modal isOpen={open} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "white", borderRadius: "15px" }}>
          <div className="modal-header">
            <button aria-label="Close" className="close" type="button" style={{backgroundColor: "white" , color:"black"}} onClick={() => setOpen(false)} color="info">
              x
            </button>
            <h3 style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
            <b>Estimados Usuarios:</b><br></br>
            Informamos que contamos con nuevos canales de comunicación para atender sus consulta y solicitudes.<br></br>
            Telefonos:<br></br>
            <b>6015144451, </b>
            <b>6015733331, </b> 
            <b>6012240347</b><br></br>
            Celulares:<br></br>
            <b>3164160715, </b>
            <b>3183597706</b><br></br>
            </h3>
          </div>
        </div>
      </Modal>
      <SectionSummary />
      <SectionComponents />
      <SectionContent />
      <SectionContactUs />
      <Logos />
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              data-number="1"
              href="#servicios"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("servicios").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Servicios</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#aplicativos"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("aplicativos").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Aplicativos</span>
            </a>
          </li>
          <li>
            <a
              data-number="3"
              href="#oficinas"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("oficinas").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Oficinas</span>
            </a>
          </li>
          
          <li>
            <a
              data-number="4"
              href="#contact-us"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("contact-us").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Contáctenos</span>
            </a>
          </li>
          
          
        </ul>
      </nav>
      
    </>
  );
}

export default Presentation;
