import React from "react";

// reactstrap components
import {

  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";


function AfectarPublico() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      {/* <BlogPostHeader /> */}
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
            Información sobre decisiones que pueden afectar al público
            </h3>
            <Row>
              <Col md="12">
                {/* IMAGEN EN CONTRUCCIÓN */}
                <Row className="justify-content-center">
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/1334 2019.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                        alt="Imagen con referencia Circular 1334"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">CIRCULAR 1334 DE 2019</h6>
                      </a>
                    </center>
                  </Col>
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/00850- TURNOS SÁBADOS NOT SDN.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                        alt="Imagen con referencia Turnos Sabado"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">TURNOS SABADO 2024</h6>
                      </a>
                    </center>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
export default AfectarPublico;
