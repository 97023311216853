/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core comments

function PresentationHeader() {
  return (
    <>
      
        <div
          className="page-header "
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/bogota14.jpg") + ")",
          }}
        >
          <div className="filter" />
          <div className="content-center">
            <Container>
              <div className="title-brand" style={{marginTop:'10%'}}>
                <h1 className="presentation-title">Notaría 51</h1>
                <div className="type">Bogotá</div>
                
              </div>
              <h4 className="presentation-subtitle text-center font-weight-normal">
                Servicios Notariales
              </h4>
              <br></br>
              <h3 className=" text-center font-weight-bold">
              <b>Estimados Usuarios: </b>
                Informamos que contamos con nuevos canales de comunicación para atender sus consulta y solicitudes.
                Telefonos:<br></br>
                <b> 6015144451, </b>
                <b> 6015733331, </b> 
                <b>6012240347</b><br></br>
                Celulares:
                <b> 3164160715, </b>
                <b>3183597706</b>
              </h3>
            </Container>
          </div>
          
        </div>
    </>
  );
}

export default PresentationHeader;
