import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import DangerNavbar from "components/Navbars/DangerNavbar.js";

// import Tramites from "/src/views/Molda/Tramites.js";
// core components

// sections for this page
// import Politica from "./Informacion/Informacion.js";


function Diagnostico() {
    // const [iconTabs, setIconTabs] = React.useState("1");
    // const [Modal3, setModal3] = React.useState(false);
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    // const toggle = () => setTooltipOpen(!tooltipOpen);

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <>
            <DangerNavbar />

            <div className="main" style={{ marginTop: "10em" }}>

                <Row className="justify-content-center">
                    <Col md="10">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <br></br>
                                    <br></br>
                                    <b style={{ color: "#084B8A", fontSize: "25px" }}>
                                        Planeación y presupuesto participativo.
                                    </b>
                                </CardTitle>
                                <p className="text-justify" style={{ fontSize: "14px", color: "#68696F", fontFamily: "serif" }}>
                                    <b>La planeación participativa</b> es entendida como el mecanismo mediante el cual la sociedad civil decide el
                                    rumbo de las políticas, planes, programas, proyectos o trámites de acuerdo con sus necesidades. La participación
                                    puede darse en la delimitación de los resultados e impactos esperados y en la definición de productos y de actividades.*
                                    <br></br>
                                    <br></br>
                                    <a href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                        <b style={{ color: '#045FB4' }}>
                                            * Departamento Administrativo de la Función Pública (2021). Lineamientos para publicar información en el menú participa sobre participación ciudadana en la gestión pública. Obtenido de: https://www.funcionpublica.gov.co/documents/
                                        </b>
                                    </a>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>

    );

}

export default Diagnostico;