/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";

import './FooterBlack.css'
// core components

function FooterBlack() {
  return (
    <>
      <footer className="subscribe-line subscribe-line-black" data-background-color="black">
        <Container style={{ width: '100% !important' }} >
          <div className="copyright float-left">
            <a href="/TerminosCondiciones">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Términos y condiciones</h6>
            </a>
            <a href="/PoliticasDatosPersonales">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Política de Tratamiento de Datos Personales</h6>
            </a>
            <a href="/PoliticasDerechosAutor">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Politícas de derechos de autor</h6>
            </a>
            <a href="/CertificadoAccesibilidad">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Certificado de Accesibilidad</h6>
            </a>
            <a href="/PoliticasPrivacidadWeb">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Políticas de Privacidad web</h6>
            </a>
            <a href="/MapaSitio">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Mapa del Sitio</h6>
            </a>
            <a href="/#contact-us">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Canales de Atencion al Publico</h6>
            </a>
            <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Telefono – Conmutador : (601)5144451</h6>
          </div>
          <div className="copyright position-logo" id="copyright" >
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img  src={require("assets/img/LOGO-NAV.png")} alt="Logo de la notaría 51 de Bogotá" width="110px"></img>
            </NavbarBrand>
          </div>
        </Container>
        <Container style={{ width: '100% !important' }} >
          <div className="div-sinfony">
            <div className="float-left">
              <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                <img
                  alt="Logo de Instagram"
                  src={require("assets/img/instagram.jpg")} style={{ height: '40px', borderRadius: "15px" }}
                ></img>
              </a>
              <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                <img
                  alt="Logo de Twitter"
                  src={require("assets/img/twitter.png")} style={{ height: '40px', borderRadius: "15px" }}
                ></img>
              </a>
              <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                <img
                  alt="Logo de Facebook"
                  src={require("assets/img/facebook.png")} style={{ height: '40px', borderRadius: "15px" }}
                ></img>
              </a>
            </div>
            © {new Date().getFullYear()}, Designed by{'\u00A0'}
            <a
              href="https://www.sinfony.com.co/sinfony/"
              target="_blank"
              rel="noreferrer"
              className="item-footer-color"
            >
              SINFONY Todos los derechos reservados.
            </a>
          </div>
        </Container>
      </footer>
      {/* <div className="subscribe-line subscribe-line-black">
        <Container>
          <Row >
            <Col className="col-6">
              <p style={{ fontSize: "16px", fontWeight: 500, color: 'white' }}>
                Tel: +57 (7) 6421111{" "}
              </p>
            </Col>
            <Col className="col-6 text-right">

              <span
                className="copyright"
                style={{ fontSize: "16px", fontWeight: 500, color: 'white' }}
              >
                © {new Date().getFullYear()}, Todos los derechos reservados.
              </span>
            </Col>
            <Col className="col-12">
              <p style={{ fontSize: "16px", fontWeight: 500, color: 'white' }}>
                Correo Físico: Carrera 21 # 22-30/40 barrio Alarcón del municipio de Bucaramanga.
              </p>
            </Col>
            <Col className="col-12">
              <p style={{ fontSize: "16px", fontWeight: 500, color: 'white' }}>
                Correo Institucional: decimabucaramaga@supernotariado.gov.co
              </p>
            </Col>
            <Col className="col-12">
              <p style={{ fontSize: "16px", fontWeight: 500, color: 'white' }}>
                Correo Notarial: notariadecimabucaramanga@gmail.com
              </p>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
}

export default FooterBlack;
