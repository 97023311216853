import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

function InfoAdicional() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Información Adicional
            </h3>
            <center className="mt-5">
              <a href={require("assets/pdf/2.9.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                <img
                alt="Imagen con referencia Resolucion N 05020"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Resolución No. 05020</h6>
              </a>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default InfoAdicional;
