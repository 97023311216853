import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

function Organigrama() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Organigrama
            </h3>
            <Row>
              <Col md="12">
                <Row className="justify-content-center">
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/ORGANIGRAMA NOTARÍA 51 DE BÓGOTA.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="Imagen con referencia Organigrama"
                          width="80px"
                          src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">ORGANIGRAMA</h6>
                      </a>
                    </center>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
                <Col className="col-12 text-center">
                <img
                              alt="Organigrama de la notaría 51 de Bogotá"
                              className="img-rounded img-responsive"
                              src={require("assets/img/organigrama.png")}
                            />
                </Col>
            </Row>


            <p className="my-4" style={{ textAlign: "justify", fontWeight:500 }}>
            
            </p>

            
            
            
          </Container>
        </div>
      </div>
    </>
  );
}

export default Organigrama;
