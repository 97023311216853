
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

// styles
import "assets/css/bootstrap.min.css";
import "assets/css/paper-kit.css";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";


//Componentes
import ColorNavbar from "components/Navbars/ColorNavbar.js"
import Presentation from "views/Presentation.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import BlogPost from "views/examples/BlogPost.js";
import Error404 from "views/examples/Error404.js";
import Error422 from "views/examples/Error422.js";
import Error500 from "views/examples/Error500.js";
import Cotizador from "views/examples/Cotizador.js"
import Solicitu from "views/Solicitudes/Solicitu.js";
import SectionTransparencia from "views/sections-sections/Transparencia.js";
import PoliticasDatosPersonales from "views/sections-sections/PoliticasDatosPersonales";
import PoliticasDerechosAutor from "views/sections-sections/PoliticasDerechosAutor";
import PoliticasPrivacidadWeb from "views/sections-sections/PoliticasPrivacidadWeb";
import CertificadoAccesibilidad from "views/sections-sections/CertificadoAccesibilidad";
import TerminosCondiciones from "views/sections-sections/TerminosCondiciones";
import MapaSitio from "views/sections-sections/MapaSitio";
import Notario from "views/sections-sections/Notario";
import InfoAdicional from "views/sections-sections/InfoAdicional";
import MisionVision from "views/sections-sections/MisionVision.js";
import Procesos from "views/sections-sections/Procesos.js";
import Organigrama from "views/sections-sections/Organigrama.js";
import Normativa from "views/sections-sections/Normativa.js";
import InfoTramites from "views/sections-sections/InfoTramites.js";
import Tarifas from "views/sections-sections/Tarifas.js";
import Web1 from "views/sections-sections/Web1.js";
import Web2 from "views/sections-sections/Web2.js";
import AfectarPublico from "views/sections-sections/AfectarPublico.js";
import Mujeres from "views/sections-sections/Mujeres.js";
import Otrosin from "views/sections-sections/Otrosin.js";
import ClasificadaReservada from "views/sections-sections/ClasificadaReservada.js";
import RetencionDocumental from "views/sections-sections/RetencionDocumental.js";
import GestionInformacion from "views/sections-sections/GestionInformacion.js";
import GestionDocumental from "views/sections-sections/GestionDocumental.js"; 

import Participa from "views/menu-participa/Participa.js";
import Diagnostico from "views/menu-participa/diagnostico-identificacion-problemas.js";
import Planeacion from "views/menu-participa/planeacion-presupuesto-participativo.js";
import Rendicion from "views/menu-participa/rendicion-cuenta.js";
import Consulta from "views/menu-participa/consulta-ciudadana";
import Control from "views/menu-participa/control-social";
import Colaboracion from "views/menu-participa/colaboracion-innovacion-abierta";
import MapaProcesos from "views/sections-sections/MapaProcesos.js"
import Decisiones from "views/sections-sections/Decisiones.js"

// others
import "config"

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <div className='dark-mode'>
        <ColorNavbar/>
          <Routes>
            <Route
              path="/Tarifas"
              element={<Tarifas />}
            />
            <Route
              path="/tramites"
              element={<BlogPost />}
            />
            <Route
              path="/blog-post"
              element={<BlogPost />}
            />
            <Route
              path="/InfoAdicional"
              element={<InfoAdicional />}
            />
            <Route
              path="/notario"
              element={<Notario />}
            />
            <Route
              path="/normativa"
              element={<Normativa />}
            />
            <Route
              path="/procesos"
              element={<Procesos />}
            />
            <Route
              path="/MisionVision"
              element={<MisionVision />}
            />
            <Route
              path="/PoliticasDatosPersonales"
              element={<PoliticasDatosPersonales />}
            />
            <Route
              path="/PoliticasDerechosAutor"
              element={<PoliticasDerechosAutor />}
            />
            <Route
              path="/PoliticasPrivacidadWeb"
              element={<PoliticasPrivacidadWeb />}
            />
            <Route
              path="/CertificadoAccesibilidad"
              element={<CertificadoAccesibilidad />}
            />
            <Route
              path="/TerminosCondiciones"
              element={<TerminosCondiciones />}
            />
            <Route
              path="/MapaSitio"
              element={<MapaSitio />}
            />
            <Route
              path="/participa" 
              element={<Participa/>}
            />
            <Route
              path="/participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas" 
              element={<Diagnostico/>}
            />
            <Route
              path="/planeacion-y-presupuesto-participativo" 
              element={<Planeacion/>}
            />
            <Route
              path="/consulta-ciudadana" 
              element={<Consulta/>}
            />
            <Route
              path="/rendicion-de-cuentas" 
              element={<Rendicion/>}
            />
            <Route
              path="/control-social" 
              element={<Control/>}
            />
            <Route
              path="/colaboracion-e-innovacion-abierta" 
              element={<Colaboracion/>}
            />
            <Route
              path="/transparencia/:modalNombre"
              element={<SectionTransparencia />}
            />
            <Route
              path="/cotizador"
              element={<Cotizador />}
            />
            <Route
              path="/InfoTramites"
              element={<InfoTramites />}
            />
            <Route
              path="/ClasificadaReservada"
              element={<ClasificadaReservada />}
            />
            <Route
              path="/GestionDocumental"
              element={<GestionDocumental />}
            />
            <Route
              path="/Web1"
              element={<Web1 />}
            />
            <Route
              path="/Web2"
              element={<Web2 />}
            />
            <Route
              path="/peticion"
              element={<Solicitu />}
            />
            <Route
              path="/GestionInformacion"
              element={<GestionInformacion />}
            />
            <Route
              path="/RetencionDocumental"
              element={<RetencionDocumental />}
            />
            <Route
              path="/AfectarPublico"
              element={<AfectarPublico />}
            />
            <Route
              path="/Organigrama"
              element={<Organigrama />}
            />
            <Route
              path="/Mujeres"
              element={<Mujeres />}
            />
            <Route
              path="/Otrosin"
              element={<Otrosin />}
            />
            <Route
              path="/error-404"
              element={<Error404 />}
            />
            <Route
              path="/error-422"
              element={<Error422 />}
            />
            <Route
              path="/error-500"
              element={<Error500 />}
            />
            <Route
              path="/MapaProcesos"
              element={<MapaProcesos />}
            />
            <Route
              path="/Decisiones"
              element={<Decisiones />}
            />
            <Route
              path="/"
              element={<Presentation />}
            />
          </Routes>
          <FooterBlack />
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
      <a href="https://wa.me/573183597706" className="float" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
      </div>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
