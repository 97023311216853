import React from "react";
import { Link } from "react-router-dom";
import { useSelector} from "react-redux";
import Axios from "axios";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";

// core components

function SectionContactUs() {
  const [checkedEmail, setCheckedEmail] = React.useState(false);
  const [nombre, setNombre] = React.useState('');
  const [correo, setCorreo] = React.useState('');
  const [mensaje, setMensaje] = React.useState('');
  const [errorEmail, setErrorEmail] = React.useState({mostrar:false, tipo:'danger', message:''});
  
  const notaria = useSelector((state) => state.notaria.notaria);


  const enviarCorreo = async (e) => {
    e.preventDefault()
   
    if(checkedEmail && nombre !==''&& correo !=='' && mensaje !==''){
      console.log(checkedEmail)
      console.log(nombre)
      console.log(correo)
      console.log(mensaje)

      try{
          if(!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(correo)){
            setErrorEmail({mostrar:true, tipo:'danger', message:'Correo no válido'})
            return
          } 
          let config = {
            method: 'POST',
            mode: 'cors',
            headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
            }
            let data = {
              'remitente_name': nombre, 
              'remitente_email': correo, 
              'descripcion':mensaje,
              'destinatario': notaria.email_contactenos            
            }            
          const resultado = await Axios.post(process.env.REACT_APP_UrlApi+'MailContact',data,config)
          
          if(resultado.status === 200){
            setNombre('')
            setCorreo('')
            setMensaje('')
            setErrorEmail({mostrar:true, tipo:'success', message:'Correo Enviado, pronto le daremos una respuesta'})       
          }else{
            setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
          }
      }
      catch(eroror){
        setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
      }
    }else{
      setErrorEmail({mostrar:true, tipo:'danger', message:'Campos vacíos'})
      setTimeout(() => {
        setErrorEmail({mostrar:false, tipo:'danger', message:''})
      }, 3000)
    }
   
  }  

  return (
    <>
      <div className="section section-contactus cd-section" id="contact-us">
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/bogota13.jpg") + ")"
          }}
        >
          <div className="content-center">
            <Container className="basic-container">
              <h3 className="presentation-subtitle text-center font-weight-normal">
                ¿Necesita más información?
              </h3>
              <p
                className="text-center font-weight-normal text-white py-3"
                style={{ fontSize: "17px" }}
              >
                Visite nuestras oficinas o comuníquese con nuestras líneas de
                atención al cliente para recibir información adicional.
              </p>
            </Container>
          </div>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Información de Contacto
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto pl-sm-3" md="5">
                      <CardBody
                        className="py-4"
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        <div className="info info-horizontal">
                          <div className="icon icon-warning">
                            <i className="nc-icon nc-pin-3" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Estamos ubicados</h4>
                            <p>
                              <b>Dirección:</b> Avenida Calle 80 70F - 55
                              <br />
                              Bogotá, Colombia <br />
                              <b>Horarios:</b><br />
                              Lunes a Viernes<br />
                              8:00 - 1:00 pm<br />
                              2:00 - 5:00pm<br />
                              Sábados<br />
                              9:00 - 1:00 pm <br />
                            </p>
                            <p>
                              <b>Correo Fisico:</b> Avenida Calle 80 70F - 55
                              <br />
                              Bogotá, Colombia <br />
                              
                            </p>
                          </div>
                        </div>
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Contacto</h4>
                            <p>
                              <b>Teléfono:</b> <br />
                              - 6012240347<br />
                              - 6015733331<br />
                            </p>
                            <p>
                              <b>Correo Institucional:</b> <br />
                              <span>cincuentayunobogota@supernotariado.gov.co</span>
                              <br/><b>Notificaciones Judiciales:</b> <br />
                              <span>notaria51bogota@gmail.com</span>
                            </p>
                          </div>
                          <div>
                            <Row className="my-2">
                              <Col md="6" sm="6">
                                <div className="">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.waze.com/es/livemap/directions/colombia/cundinamarca/bogota/notaria-51?place=ChIJ_WPxMzybP44REU7tDmUFjVs&utm_campaign=waze_website&utm_expid=.K6QI8s_pTz6FfRdYRPpI3A.0&utm_referrer=&utm_source=waze_website"
                                  >
                                    <img
                                      alt="Logo de la aplicación de Waze"
                                      src={require("assets/img/sections/waze-logo.png")}
                                      //  style={{ width: "50%", height: "50%", marginLeft:"57px" }}
                                      className="waze"
                                    ></img>
                                  </a>
                                </div>
                              </Col>
                              <Col md="6" sm="6">
                                <div className="">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.google.com/maps/dir/?api=1&destination=Notaria+51,+Cl.+80+%237055,+Bogotá,+Cundinamarca"
                                  >
                                    <img
                                      alt="Logo de la aplicación de Google Maps"
                                      src={require("assets/img/sections/maps.png")}
                                      //  style={{ width: "32%", height: "42%" }}
                                      className="maps"
                                    ></img>
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="mr-auto pt-3 " md="5">
                      <Form id="contact-form" method="post" role="form">
                        <CardBody
                          className="py-4"
                          style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                          <Row>
                            <Col md="12" className="">
                              <FormGroup className="label-floating">
                                <label
                                  className="control-label font-weight-normal"
                                  style={{ fontSize: "15px" }}
                                >
                                  Nombre
                                </label>
                                <Input
                                  name="name"
                                  placeholder="Nombres"
                                  type="text"
                                  onChange={(e)=>{setNombre(e.target.value)}}
                                />
                              </FormGroup>
                            </Col>                          
                          </Row>
                          <FormGroup className="label-floating">
                            <label
                              className="control-label font-weight-normal"
                              style={{ fontSize: "15px" }}
                            >
                              Correo Electronico
                            </label>
                            <Input
                              name="email"
                              placeholder="Correo Electronico"
                              type="email"
                              onChange={(e)=>{setCorreo(e.target.value)}}
                            />
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label
                              className="control-label font-weight-normal"
                              style={{ fontSize: "15px" }}
                            >
                              Mensaje
                            </label>
                            <Input
                              id="message"
                              name="message"
                              placeholder="Mensaje"
                              type="textarea"
                              rows="6"
                              onChange={(e)=>{setMensaje(e.target.value)}}
                            />
                          </FormGroup>
                          <Row>
                            <Col sm="6">
                              <FormGroup check className="my-2">
                                <Label className="font-weight-normal" check>
                                  <Input
                                    id="check"
                                    defaultValue=""
                                    type="checkbox"
                                    onClick={ e => setCheckedEmail(e.target.checked) }
                                  />
                                  No soy un robot{" "}
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <Button
                                block
                                className="pull-right my-2"
                                style={{
                                  backgroundColor: "#3cbc12",
                                  borderColor: "#3cbc12"
                                }}
                                type="submit"
                                onClick={(e)=>enviarCorreo(e)}
                              >
                                Enviar
                              </Button>
                            </Col>
                          </Row>
                          <Row >
                          {errorEmail.mostrar && (
                              <Col md="12">
                                <div
                                 /*  className={"alert alert-"+errorEmail.tipo+" font-weight-normal my-1"} */
                                  className={"alert alert-danger font-weight-normal my-1"}
                                  role="alert"
                                >
                                  {errorEmail.message}
                                </div>
                              </Col>
                            )}
                          </Row>
                          
                        </CardBody>
                      </Form>
                    </Col>
                  </Row>
                  <Row style={{marginTop:'-22px'}} >
                            
                            <Col md="8" className="text-center ml-auto mr-auto mb-5">
                              <h4 className="mb-4">Atención al Público</h4>
                              <Button
                                className="btn-rotate btn-block btn-round mr-1 "
                                color="default"
                                to="/peticion" tag={Link}
                              >
                                {/* <i className="nc-icon nc-settings-gear-65 mr-1" /> */}
                                <i className="fa fa-hand-pointer-o" aria-hidden="true"></i>
                                PQRS
                              </Button>
                            </Col>
                          </Row>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" md="4">
                <div className="" id="contactUsMap2">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.4359722398635!2d-74.08920298573676!3d4.694060643023369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b24e5d067f7%3A0xf3c9f73e6ad92d5d!2sCl.%2080%20%2370f-55%2C%20Bogot%C3%A1!5e0!3m2!1ses!2sco!4v1594052848629!5m2!1ses!2sco"
                    width="350"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0, marginTop: "87px" }}
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END CONTACT US 1 ********* */}
        {/* ********* CONTACT US 2 ********* */}

        {/* ********* END CONTACT US 2 ********* */}
      </div>
    </>
  );
}

export default SectionContactUs;
