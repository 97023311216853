import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";


function MapaProcesos() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
            Mapa de Procesos
            </h3>
            <Row>
              <Col md="12">
                <Row className="justify-content-center">
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/TABLA ANEXA - DESCRIPCIÓN ACTOS NOTARIALES (BORRADOR) (1).pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                        alt="Imagen con referencia Actos Notariales"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3" >DESCRIPCIÓN ACTOS NOTARIALES</h6>
                      </a>
                    </center>
                  </Col>
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/DIVORCIO MATRIMONIO CIVIL CON HIJOS MENOR v1.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                        alt="Imagen con referencia Matrimonio Civvil Hijos"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">DIVORCIO MATRIMONIO CIVIL CON HIJOS MENOR</h6>
                      </a>
                    </center>
                  </Col>
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/DIVORCIO MATRIMONIO CIVIL v1.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                        alt="Imagen con referencia Divorcio Matrimonio Civil"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">DIVORCIO MATRIMONIO CIVIL</h6>
                      </a>
                    </center>
                  </Col>
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/PROCEDIMIENTODEFINITIVESCRITURACIÓN.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                        alt="Imagen con referencia Procedimiento Escrituracion"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">PROCEDIMIENTO ESCRITURACIÓN</h6>
                      </a>
                    </center>
                  </Col>
                  <Col md="2">
                    <center className="mt-5">
                      <a href={require("assets/pdf/SUCESIONES.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                        <img
                        alt="Imagen con referencia Sucesiones"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">SUCESIONES</h6>
                      </a>
                    </center>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default MapaProcesos;
