import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

function Web1() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Formato alternativo para grupos étnicos y culturales
            </h3>
            <Row className="mt-4">
              <Col className="col-12 text-center">
                <div>
                  <center>
                    <iframe width="453" height="240" src="https://www.youtube.com/embed/V-nb0Y5haos" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </center>
                </div>
                <div>
                  <center>
                    <iframe width="453" height="240" src="https://www.youtube.com/embed/zoXVd3oOZs4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </center>
                </div>
                <div>
                  <center>
                    <iframe width="453" height="240" src="https://www.youtube.com/embed/3netCiMR0JI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </center>
                </div>
              </Col>
            </Row>           
          </Container>
        </div>
      </div>
    </>
  );
}

export default Web1;
