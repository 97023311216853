import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

function Notario() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Biografia
            </h3>
            <p style={{ textAlign: "justify", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
              Rubén Dario Acosta González, nación en Bogotá, Cundinamarca. Abogado egresado de la facultad de Derecho, Ciencias Políticas y Sociales de la Univerdidad Nacional de Colombia. Especializado en Derecho Penal y Penitenciarias de la Universidad Nacional de Colombia, Especialista en Procesos Civiles y Comerciales. Cargos desempeñados: Notario 75 del Círculo de Bogotà, Asesor Comision Nacional de Televisión, Superintendente Delegado de Notariado- Director Vigilancia Notarial de la Superintendencia de Notariado y Registro, Gerente de la Corporación de los Centros de Convenciones y Exposiciones de Colombia, Jefe de la Oficina de Control Operativo del Fondo DRI, Auditor Nacional del Instituto de los Seguros Sociales, Jefe de la Unidad de Asuntos Penales del IDEMA, entre otros.
            </p>
            <p>             
              <li><b>Correo Institucional: </b>notaria51bogota@gmail.com</li>
              <li><b>Télefono Institucional: </b>
              +57 3183597706</li>
            </p>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Notario;
