import React from "react";

// reactstrap components
import {

  Container

} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

function PoliticasPrivacidadWeb() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "10em" }}>
        <div className="section">
          <Container>
            <h3 className="title text-center" style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Politica de Privacidad Web
            </h3>
            <center className="mt-5">
              <a href={require("assets/pdf/Manual-de-Politicas-de-Privacidad-Web.pdf")} style={{ textDecoration: "none !important" }} target="_blank" rel="noopener noreferrer">
                <img
                  alt="Imagen con referencia Privacidad web"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Política de Privacidad Web</h6>
              </a>
            </center>
            <center className="mt-5">
                <img
                  alt="Seguridad AWS"
                  width="300px"
                  src={require("assets/img/sections/certificado.png")}
                ></img>
                <h6 className="my-3">Certificado Seguridad AWS</h6>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default PoliticasPrivacidadWeb;
