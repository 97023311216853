import React from "react";

// reactstrap components
import { 
  Container, 
  Row, 
  Col,
  Card,
 } from "reactstrap";

// core components

function SectionContent() {
  return (
    <>
      <div id="fadeInAnim">
        <div className="section section-content cd-section section-gray" id="oficinas">
          <Container>
            <h2 className="pb-5 text-center">Nuestras Oficinas</h2>
            <h6 className=" pb-5 text-center">Tenemos un ambiente adecuado para que usted realice sus trámites.</h6>
            <Row className="py-3">
            <Col md="3">
                <Card
                >
                  <div className="card-image">
                    <img
                      alt="Entrada de la Notaría 51 de Bogotá"
                      className="img"
                      src={require("assets/img/sections/office15.jpg")}
                    />
                  </div>
                  
                </Card>
              </Col>
              <Col md="3">
              <Card
                  >
                    <div className="card-image">
                      <img
                        alt="Vista de la terraza de la notaría 51 de Bogotá"
                        className="img"
                        src={require("assets/img/sections/office8.jpg")}
                      />
                    </div>
                    
                  </Card>
              </Col>
              <Col md="3">
              <Card
                  >
                    <div className="card-image">
                      <img
                        alt="Recepción de la notaría 51 de Bogotá"
                        className="img"
                        src={require("assets/img/sections/office11.jpg")}
                      />
                    </div>
                    
                  </Card>
              </Col>
              <Col md="3">
              <Card
                  >
                    <div className="card-image">
                      <img
                        alt="Entrada vista por dentro de la notaría 51 de Bogotá"
                        className="img"
                        src={require("assets/img/sections/office6.jpg")}
                      />
                    </div>
                    
                  </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
              <Card
                  >
                    <div className="card-image">
                      <img
                        alt="Recepción de la notaría 51 de Bogotá"
                        className="img"
                        src={require("assets/img/sections/office1.jpg")}
                      />
                    </div>
                    
                  </Card>
              </Col>
              <Col md="3">
              <Card
                  >
                    <div className="card-image">
                      <img
                        alt="Recepción de la notaría 51 de Bogotá"
                        className="img"
                        src={require("assets/img/sections/office3.jpg")}
                      />
                    </div>
                    
                  </Card>
              </Col>
              <Col md="3">
              <Card
                  >
                    <div className="card-image">
                      <img
                        alt="Oficina de la notaría 51 de Bogotá"
                        className="img"
                        src={require("assets/img/sections/office9.jpg")}
                      />
                    </div>
                    
                  </Card>
              </Col>
              <Col md="3">
              <Card
                  >
                    <div className="card-image">
                      <img
                        alt="Oficina de la notaría 51 de Bogotá"
                        className="img"
                        src={require("assets/img/sections/office10.jpg")}
                      />
                    </div>
                    
                  </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
