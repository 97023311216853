import React from "react";

// reactstrap components

// core components

function BlogPostHeader() {
  let pageHeader = React.createRef();

 

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/bogota12.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <div className="motto">
            <h1 className="title-uppercase text-center">Trámites</h1>
            <h3 className="text-center">Servicios Notariales | Notaria 51</h3>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogPostHeader;
