import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

function MisionVision() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Misión:
            </h3>
            <p className="my-4" style={{ textAlign: "justify", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
              Prestamos servicios notariales a nuestros usuarios, con los más altos estándares de calidad, guardando la fe pública dentro del marco de la constitución y las leyes de Colombia, generando seguridad jurídica y transparencia, en pro de la satisfacción y bienestar de quienes demandan nuestros servicios. 
            </p>
            <h3 className="title-uppercase" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Visión:
            </h3>
            <p className="my-4" style={{ textAlign: "justify", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
              Ser una notaría que responda de manera eficiente a los desafíos que genera el creciente y constante avance de la tecnología, buscando el mejoramiento continuo en pro del beneficio de nuestros usuarios y demás partes interesadas, con procesos tecnificándose, ágiles, eficaces y oportunos, estando a la vanguardia frente a los desafíos tecnológicos, jurídicos y sociales.
            </p>
          </Container>
        </div>
      </div>
    </>
  );
}

export default MisionVision;
