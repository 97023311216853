import React from "react";
import Pqrs from './pqrs.js';
import DangerNavbar from "components/Navbars/DangerNavbar.js";

// reactstrap components
import { Container, 
          Row 
} from "reactstrap";

// core components

function Solicitu() {
  return (
    <>
     <DangerNavbar />
     <div className="main" style={{ marginTop: "8em" }}> 
      <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
        <Container className="fluid">
            <Row className="justify-content-center">              
              <Pqrs 
              color= {"#2C850B"} 
              nombreNotaria={"51 de Bogotá"} 
              terminosNotaria={"/politicas"}>
              </Pqrs>              
            </Row> 
        </Container>
       </div>
      </div>
    </>
  );
}

export default Solicitu;
