import React from "react";

// reactstrap components
import { Container, Row, Col , Button,Modal} from "reactstrap";
// core components

function SectionSummary() {
  // modals states
  const [escritura, setEscritura] = React.useState(false);
  const [civil, setCivil] = React.useState(false);
  const [notice, setNotice] = React.useState(false);

  return (
    <>
      <div className="section section-dark cd-section section-summary" id="servicios">
        <Container>
          <Row className="mt-5">
            <Col md="4">
              <div className="info">
                <div className="icon " style={{color:'#94CD12'}}>
                  <i className="nc-icon nc-layout-11" />
                </div>
                <div className="description">
                  <h4 className="info-title">Escrituración</h4>
                  <p>
                  La escritura pública es un instrumento notarial que contiene una o más declaraciones de las personas intervinientes en un acto o contrato, emitidos ante notario con el lleno de los requisitos legales propios y específicos de cada acto, para su incorporación al protocolo.
                  </p>
                  <div className="mt-4">
                    <Button
                      className="btn-link"
                      color="success"
                      style={{color:'#94CD12'}}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setEscritura(true);
                      }}
                      >
                      Ver más
                    </Button>
                  </div>
                  {/* MODALS */}
                  <Modal isOpen={escritura} toggle={() => setEscritura(false)} >
                    <div className="modal-header">
                      <button
                      className="close"
                      type="button"
                      onClick={() => setEscritura(false)}
                      >
                      <span>×</span>
                      </button>
                      <h5
                      className="modal-title text-center font-weight-bold"
                      id="exampleModalLabel"
                      >
                      <span className="icon pr-3" style={{color:'#94CD12'}}>
                        <i className="nc-icon nc-layout-11 font-weight-bold" style={{fontSize:'30px'}}/>  
                      </span>
                      Requisitos
                      </h5>
                    </div>
                    <div className="modal-body font-weight-normal " >
                      <ul className="list-unstyled" style={{fontSize:'16px'}}> 
                        <li>Los requisitos son los establecidos por la ley para cada uno de los actos o contratos que se van a otorgar.</li>
                        <li> <i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2 "></i> Asistir personalmente a la notaria </li>
                        <li> <i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2"></i> Llevar cédula de ciudadania</li>
                        <li> <i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2"></i> Llevar documento privado que se va a firmar</li>
                        <li> <i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2"></i> Llevar testigo cuando asi se requiera</li>
                      </ul>
                    </div>
                    <div className="modal-footer " >
                      <Button
                      className="btn-link font-weight-bold "
                      type="button"
                      onClick={() => setEscritura(false)}
                      >
                      <span style={{fontSize:'14px', color:'#94CD12'}} >Ok</span>
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon " style={{color:'#94CD12'}}>
                  <i className="nc-icon nc-tile-56" />
                </div>
                <div className="description">
                  <h4 className="info-title">Registro Civil</h4>
                  <p>
                  Es un documento indispensable mediante el cual la persona prueba ante la familia y la sociedad su existencia y le permite identificarse, ejercer sus derechos y cumplir sus obligaciones.
                  </p>
                  <div >
                    <Button
                      className="btn-link"
                      color="success"
                      style={{color:'#94CD12'}}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setCivil(true);
                      }}
                      >
                      Ver más
                    </Button>
                  </div>
                  {/* MODALS */}
                  <Modal isOpen={civil} toggle={() => setCivil(false)} >
                    <div className="modal-header">
                      <button
                      className="close"
                      type="button"
                      onClick={() => setCivil(false)}
                      >
                      <span>×</span>
                      </button>
                      <h5
                      className="modal-title text-center font-weight-bold"
                      id="exampleModalLabel"
                      >
                      <span className="icon pr-3" style={{color:'#94CD12'}}>
                        <i className="nc-icon nc-tile-56 font-weight-bold" style={{fontSize:'30px'}}/>  
                      </span>
                      Requisitos
                      </h5>
                    </div>
                    <div className="modal-body font-weight-normal ">
                      <ul className="list-unstyled" style={{fontSize:'16px' }}>
                        <li><i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2 "></i>Certificado de nacido vivo</li>
                        <li><i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2 "></i>Documentos de identificación de los padres</li>
                      </ul>
                      <p  style={{fontSize:'16px'}}  className="font-weight-normal">La inscripción de registro civil no tiene costo.</p>
                    </div>
                    <div className="modal-footer " >
                      <Button
                      className="btn-link font-weight-bold "
                      style={{color:'#94CD12'}}
                      type="button"
                      onClick={() => setCivil(false)}
                      >
                      <span style={{fontSize:'14px'}} >Ok</span>
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon" style={{color:'#94CD12'}}>
                  <i className="nc-icon nc-paper" />
                </div>
                <div className="description">
                  <h4 className="info-title">Reconocimiento de Documentos Privados y Autenticaciones</h4>
                  <p>
                    Es cuando el notario da testimonio escrito de las firma que aparecen en un documento privado fueron puestas en su presencia, estableciendo la identidad de los firmantes.
                  </p>
                  <div className="mt-4">
                    <Button
                      className="btn-link"
                      color="success"
                      style={{color:'#94CD12'}}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setNotice(true);
                      }}
                      >
                      Ver más
                    </Button>
                  </div>
                  {/* MODALS */}
                  <Modal isOpen={notice} toggle={() => setNotice(false)} >
                    <div className="modal-header">
                      <button
                      className="close"
                      type="button"
                      onClick={() => setNotice(false)}
                      >
                      <span>×</span>
                      </button>
                      <h5
                      className="modal-title text-center font-weight-bold"
                      id="exampleModalLabel"
                      >
                      <span className="icon pr-3" style={{color:'#94CD12'}}>
                        <i className="nc-icon nc-paper font-weight-bold" style={{fontSize:'30px'}}/>  
                      </span>
                      Requisitos
                      </h5>
                    </div>
                    <div className="modal-body font-weight-normal ">
                      <ul className="list-unstyled" style={{fontSize:'16px'}}>
                      <li> <i className="fa fa-angle-double-right fa-2x fa-fw pr-3 "></i> Asistir personalmente a la notaría. Puede  </li>
                      <span className="ml-4 pb-2">hacerse también a domicilio </span>
                      <li><i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2 "></i>Llevar cédula de ciudadania </li>
                      <li><i className="fa fa-angle-double-right fa-2x fa-fw pr-4 pb-2 "></i>Llevar el documento que se va a autenticar </li>
                      </ul>
                    </div>
                    <div className="modal-footer " >
                      <Button
                      className="btn-link font-weight-bold "
                      color="success"
                      style={{color:'#94CD12'}}
                      type="button"
                      onClick={() => setNotice(false)}
                      >
                      <span style={{fontSize:'14px'}}>Ok</span>
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummary;
