/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Card,CardBody } from "reactstrap";

// core components

function Logos() {
  return (
    <>
      <Card style={{marginBottom:'2em'}}>
        <CardBody>
          <b>Entidades, agremiaciones y asociaciones</b>
          <div className="row">
            <div className="col-md-1 col-sm-2" style={{ marginLeft:'4em' }}>
              <a  target="_blank" rel="noopener noreferrer" href="https://www.procuraduria.gov.co/portal/">
                <img
                  alt="Logo de Procuraduria General de la Nacion"
                  className="log1"
                  src={require("assets/img/sections/procuraduria.jpg")}
                  style={{ marginLeft:'3em',marginTop:'21px' }}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="http://www.minjusticia.gov.co/">
                <img
                  alt="Logo de Ministero de Justicia y Derecho de Colombia"
                  className="log"
                  src={require("assets/img/sections/minJusticia2.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.supernotariado.gov.co/">
                <img
                  alt="Logo de SNR y Registro"
                  className='log'
                  src={require("assets/img/sections/SNR.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-1 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.vur.gov.co/">
                <img
                  alt="Logo del VUR"
                  className="logos2"
                  src={require("assets/img/sections/vur.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-1 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.registraduria.gov.co/">
                <img
                  alt="Logo Registraduria Nacional del estado civil"
                  className="logos2"
                  src={require("assets/img/sections/registraduria.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-1 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.suin-juriscol.gov.co/">
                <img
                  alt="Logo SUIN Juriscol"
                  className="logos2"
                  src={require("assets/img/sections/suin.png")}
                ></img>
              </a>
            </div>
          </div >
        </CardBody>
      </Card>
      <Card style={{marginBottom:'0px'}}>
        <CardBody>
          <b>Directorio de agremiaciones, asociaciones y otro grupos de interesés</b>
          <div className="row text-center">
            <div className="col-md-2 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.ucnc.com.co/sitio/">
                <img
                  alt="Logo de la Unión Colegiada del Notariado Colombiano"
                  className='log'
                  src={require("assets/img/sections/UnionColegiala.jpg")}
                ></img>
              </a>
            </div>
            <div className="col-md-1 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.uinl.org/america-american-affairs-commission-caam-">
                <img
                  alt="Logo de la Comisión de Asuntos Americanos"
                  className="logos"
                  src={require("assets/img/sections/comision.png")}
                ></img>
              </a>
            </div>
          </div >
        </CardBody>
      </Card>
    </>
  );
}
export default Logos;
