import React from "react";

// reactstrap components
import {

  Container

} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

function PoliticasDatosPersonales() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "10em" }}>
        <div className="section">
          <Container>
            <h3 className="title text-center" style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Programa de Gestion Documental
            </h3>
            <center>
              <a
                href="http://datos.gov.co/"
                target="_black"
                style={{
                  fontWeight: 500,
                  fontFamily: "'Noto Sans SC', sans-serif",
                  cursor: 'pointer'
                }}
              >
                Enlace a Datos Abiertos
              </a>
            </center>
            <center className="mt-5">
              <a href={require("assets/pdf/Cuadro clasificacion documental.pdf")} style={{ textDecoration: "none !important" }} target="_blank" rel="noopener noreferrer">
                <img
                  alt="Imagen con referencia Gestion Documental"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Programa de Gestion Documental</h6>
              </a>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default PoliticasDatosPersonales;
