import React, { useState } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  NavbarToggler,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

function ColorNavbar(props) {

  const [isOpen, setIsOpen] = useState(false);

  const toggle2 = () => setIsOpen(!isOpen);
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    // Fija el menu
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 199 ||
        document.body.scrollTop > 199
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 200 ||
        document.body.scrollTop < 200
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>

      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Row>
        <Col md="12">
          <Navbar
            className={classnames("fixed-top", navbarColor)}
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <div className="navbar-translate">
                <NavbarBrand id="navbar-brand" to="/" tag={Link}>
                  <img
                    alt="Logo Notaría 51 de Bogotá"
                    className="img"
                    width="110"
                    height="60"

                    src={require("assets/img/sections/LLL.png")}
                  />
                </NavbarBrand>
                <button
                  className="navbar-toggler"
                  id="navigation"
                  type="button"
                  onClick={() => {
                    document.documentElement.classList.toggle("nav-open");
                    setBodyClick(true);
                    setCollapseOpen(true);
                  }}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarToggler onClick={toggle2} />
              <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar >
                  <NavItem>
                    <NavLink
                      to="/" tag={Link}
                    >
                      Inicio
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar >
                    <DropdownToggle className="mr-2" color="default" caret nav >
                      Servicios en Linea
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" end style={{ 'position': 'absolute', 'height': 'auto' }}>
                      <DropdownItem to="/cotizador" tag={Link}>Cotizador</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2" color="default" caret nav >
                      Conócenos
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" end style={{ 'position': 'absolute', 'height': 'auto' }}>
                      <DropdownItem to="/MisionVision" tag={Link} >Misión y Visión</DropdownItem>
                      <DropdownItem to="/Procesos" tag={Link} >Procesos y Procedimientos</DropdownItem>
                      <DropdownItem to="/Organigrama" tag={Link} >Organigrama</DropdownItem>
                      <DropdownItem to="/Notario" tag={Link} >Notario</DropdownItem>
                      {/* <DropdownItem to="/#servicios" tag={Link} >Servicios</DropdownItem>
                      <DropdownItem to="/#oficinas" tag={Link}>Oficinas</DropdownItem>
                      <DropdownItem to="/#contact-us" tag={Link}>Contáctenos</DropdownItem>
                      <DropdownItem to="/#aplicativos" tag={Link} >Aplicativos</DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {/* <NavItem>
                    <NavLink
                      to="/tramites" tag={Link}
                    >
                      Tramites
                    </NavLink>
                  </NavItem> */}
                  <UncontrolledDropdown nav inNavbar >
                    <DropdownToggle className="mr-2" color="default" caret nav >
                      Tramites
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" end style={{ 'position': 'absolute', 'height': 'auto' }}>
                      <DropdownItem to="/InfoTramites" tag={Link}>Información</DropdownItem>
                      <DropdownItem to="/tramites" tag={Link}>Servicios</DropdownItem>
                      <DropdownItem to="/Tarifas" tag={Link}>Tarifas</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink
                      to="/#contact-us" tag={Link}
                    >
                      Contáctenos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/Transparencia/informacionPublica" tag={Link}
                    >
                      Transparencia
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/participa"
                      style={{ color: "#f5f5f5" }}
                    >
                      Participa
                    </NavLink>
                  </NavItem>
                  {/*<NavItem>
                    <NavLink
                      //href="https://notariavirtual.notaria51bogota.com/"
                      style={{ color: "#f5f5f5" }}
                    >
                      NOTARÍA VIRTUAL
                    </NavLink>
                  </NavItem>*/}
                  <NavItem>
                  <a href="https://www.contadorvisitasgratis.com" title="contador web html"><img src="https://counter8.optistats.ovh/private/contadorvisitasgratis.php?c=c3mxhj3m6lrpwk443dzdt5yar2a25gjh" border="0" title="contador web html" alt="contador web html"/></a>
                  </NavItem>    
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </>
  );
}

export default ColorNavbar;
